var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[(_vm.clusterId)?_c('v-card-text',[_c('v-data-table',_vm._b({attrs:{"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch && _vm.$refs.confirmDelete)?_c('rs-table-title-bar',_vm._b({attrs:{"title":"Toplu Bildirimler(SMS-Mobil)","icon":"mdi-cellphone-wireless","add-route":_vm.can('edit-notification')
              ? { name: 'communication.batch-notifications.create' }
              : null,"hide-edit":!_vm.can('edit-notification'),"edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query,"show-delete":_vm.can('delete-notification'),"delete-enabled":_vm.selectedItems.length > 0 &&
            !_vm.selectedItems.filter((v) => v.status_id !== 1).length,"delete-tooltip":"En az bir satır seçmelisiniz"},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"click:edit":_vm.handleEditClick,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)},"click:delete":function($event){return _vm.$refs.confirmDelete.show(
              'Seçili satırları silmek istediğinizden emin misiniz?'
            )}}},'rs-table-title-bar',_vm.titleBarAttrs,false)):_vm._e(),_c('rs-confirm',{ref:"confirmDelete",attrs:{"loading":_vm.isLoading},on:{"confirmed":_vm.handleDeleteSubmitClick,"cancelled":function($event){return _vm.$refs.confirmDelete.hide()}}})]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'communication.batch-notifications.edit',
            params: { id: item.id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.body",fn:function({ value }){return [_c('rs-table-cell-string',{attrs:{"value":value,"limit":30}})]}},{key:"item.resident_count",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"value":value}})]}},{key:"item.notification_channel_id",fn:function({ item }){return [_vm._v(" "+_vm._s(item.notification_channel)+" ")]}},{key:"item.sent_at",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"show-time":"","value":value}})]}}],null,false,3157720822),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))],1):_c('v-card-text',[_vm._v(" Listeyi görmek için üst menüden bir toplu yaşam alanı seçin. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }