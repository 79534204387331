<template>
  <v-card>
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch && $refs.confirmDelete"
            title="Toplu Bildirimler(SMS-Mobil)"
            icon="mdi-cellphone-wireless"
            :add-route="
              can('edit-notification')
                ? { name: 'communication.batch-notifications.create' }
                : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-notification')"
            :edit-enabled="selectedItems.length === 1"
            @click:edit="handleEditClick"
            :search.sync="search.query"
            :show-delete="can('delete-notification')"
            :delete-enabled="
              selectedItems.length > 0 &&
              !selectedItems.filter((v) => v.status_id !== 1).length
            "
            delete-tooltip="En az bir satır seçmelisiniz"
            @click:delete="
              $refs.confirmDelete.show(
                'Seçili satırları silmek istediğinizden emin misiniz?'
              )
            "
            v-bind="titleBarAttrs"
          />

          <rs-confirm
            ref="confirmDelete"
            :loading="isLoading"
            @confirmed="handleDeleteSubmitClick"
            @cancelled="$refs.confirmDelete.hide()"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'communication.batch-notifications.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.body="{ value }">
          <rs-table-cell-string :value="value" :limit="30" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.resident_count="{ value }">
          <rs-table-cell-number :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.notification_channel_id="{ item }">
          {{ item.notification_channel }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.sent_at="{ value }">
          <rs-table-cell-date show-time :value="value" />
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";

export default {
  name: "BatchNotificationList",
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters([
      "clusterId",
      "notificationChannelListIds",
      "notificationStatusList",
    ]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
  data() {
    return {
      titleBarAttrs: {
        exportUrl: "batch-notifications",
        exportParams: this.getParams,
      },
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      search: {
        status_id: [1, 2, 3],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-notification"),
        },
        {
          text: "İsim",
          value: "name",
          searchable: "text",
        },
        {
          text: "Bildirim Türü",
          value: "notification_channel",
          searchable: "multiselect",
          options: () => this.notificationChannelListIds,
          width: "100px",
          searchValue: "notification_channel_id",
        },
        {
          text: "Metin",
          value: "body",
          searchable: "text",
        },
        {
          text: "Sakin Sayısı",
          value: "resident_count",
          searchable: "number",
          align: "end",
        },
        {
          text: "Durum",
          value: "status",
          searchable: "multiselect",
          searchValue: "status_id",
          options: () => this.notificationStatusList,
          width: "120px",
        },
        {
          text: "Gönderim Tarihi",
          value: "sent_at",
          searchable: "date",
        },
      ],
    };
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.clusterId) {
        params.cluster_id = this.clusterId;
      }

      return params;
    },
    loadList() {
      if (this.isLoading || !this.clusterId) {
        this.resetTable();
        return false;
      }

      this.resetTable();
      this.isLoading = true;

      const params = this.getParams();

      this.$api
        .query("batch-notifications", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDeleteSubmitClick() {
      if (this.isLoading || !this.selectedItems.length) {
        return false;
      }

      const params = {
        ids: this.selectedItems.map((item) => item.id),
      };
      this.isLoading = true;

      this.$api
        .delete("batch-notifications", params)
        .then(() => {
          this.$toast.success(this.$t("toasts.item_deleted"));
          this.$refs.confirmDelete.hide();
          setTimeout(() => this.loadList());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.loadList();
    this.generateExportColumns(this.headers);
  },
};
</script>
